import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  ButtonBase,
  InputLabel,
  Checkbox
} from '@mui/material';
import { Modal, CardMedia, CardHeader, Card, CardContent } from '@mui/material';
import { PinDrop, Cancel, Payment } from '@mui/icons-material';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { CircularProgress } from '@mui/material';
import Logo from "./kalmarry48.png"


const FancyPage = () => {
  const [isSending, setIsSending] = useState(true);
  const [pinCorrect, setPinCorrect] = useState(false);

  useEffect(() => {
    // Simulate the process of sending zaproszenia
    setTimeout(() => {
      

      // After sending is complete, check if pin is correct
      // You can implement your logic here to set pinCorrect to true or false

      // For demonstration, we'll set it to true after 3 seconds
      setTimeout(() => {
        

        // After 60 seconds, refresh the page
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }, 3000);
    }, 5000);
  }, []);

  return (
    <div>
      {pinCorrect ? (
        <>
          {/* Display your success message or any other content */}
          <h1>Zaproszenia są wysyłane do realizacji</h1>
        </>
      ) : (
        <div style={{alignItems:"center", alignContent:"center", textAlign:"center"}}>
         
              {/* Show the sending animation */}
              <CircularProgress
                  style={{ color: '#F1598C', marginBottom: '1rem' }}
                />
              <p>Dziękujemy, zaczynamy pracę nad Twoimi zaproszeniami!</p>
          
          
        </div>
      )}
    </div>
  );
};

const StyledContainer = styled(Container)`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  background-color: ${props => props.color};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    alignItems:"center",
    justifyContent:"center",
  },
  card: {
    width: '350px', 
    height: '400px', 
    margin: '10px',
    cursor: 'pointer',
  },
  cardContent: {
    height: '100%', // Fill the entire height of the card
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selected: {
    outline: '2px solid #F1598C',
  },
  modal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width:"90%",
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    background: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  envelopeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    alignItems:"center",
    justifyContent:"center"
  },
};
const OrderForm = () => {
  

  const [selectedType, setSelectedType] = useState('zaproszenia-slubne');
  const [selectedModel, setSelectedModel] = useState('a');
  const [selectedEnvelope, setSelectedEnvelope] = useState('a');
  const [quantity, setQuantity] = useState(10);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pinInputVisible, setPinInputVisible] = useState(false);
  const [pinInput, setPinInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [inviterName, setInviterName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [receptionLocation, setReceptionLocation] = useState("");
  const [guestConfirmationDate, setGuestConfirmationDate] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [orderContactNumber, setOrderContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [formError, setFormError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(!formError)
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [errorQu, setErrorQu] = useState("");
  const [isSending, setIsSending] = useState(true);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [pinCorrect, setPinCorrect] = useState(false)
  const [checkbox1, setCheckbox1] = useState();
  const [checkbox2, setCheckbox2] = useState();


  const modelData = {
  'zaproszenia-slubne': {
    'a': {
      name: 'Diagonal',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-diagonal.png',
    },
    'b': {
      name: 'Dobro',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-dobro.png',
    },
    'c': {
      name: 'Flora',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-flora.png',
    },
    'e': {
      name: 'Karolina',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-karolina.png',
    },
    'f': {
      name: 'Klasyk',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-klasyk.png',
    },
    'g': {
      name: 'Lotka',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-lotka.png',
    },
    'h': {
      name: 'Pod Różą',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-pod-roza.png',
    },
    'i': {
      name: 'Polly',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-polly.png',
    },
    'j': {
      name: 'Symetria',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-symetria.png',
    },
    'k': {
      name: 'Świeże Liście',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-swieze-liscie.png',
    },
    'l': {
      name: 'Talya',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-talya.png',
    },
    'm': {
      name: 'Verti',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-verti.png',
    },
    'n': {
      name: 'Vesper',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-vesper.png',
    },
    'o': {
      name: 'Wheatberry',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-wheatberry.png',
    },
    'p': {
      name: 'Willo',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-willo.png',
    },
    'r': {
      name: 'Wiosna',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-wiosna.png',
    },
    's': {
      name: 'Wrzosowisko',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-wrzosowisko.png',
    },
    't': {
      name: 'Yoi',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-yoi.png',
    },
    'u': {
      name: 'Aniela',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-aniela.png',
    },
    'w': {
      name: 'Biała Łąka',
      price: 6,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/slub-biala-laka.png',
    },
  },
  'zaproszenia-komunijne': {
    'c1': {
      name: 'Fleur',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/komunia-fleur.png',
    },
    'd1': {
      name: 'Lottie',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/komunia-lottie.png',
    },
    'e1': {
      name: 'Morgan',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/komunia-morgan.png',
    },
    'f1': {
      name: 'Zoya',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/komunia-zoya.png',
    },
   
  },
  'zaproszenia-chrzest': {
    'e2': {
      name: 'Fleur',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/chrzest-fleur.png',
    },
    'f2': {
      name: 'Lottie',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/chrzest-lottie.png',
    },
    'g2': {
      name: 'Morgan',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/chrzest-morgan.png',
    },
    'h2': {
      name: 'Zoya',
      price: 5.50,
      image: 'https://kalmarry.pl/wp-content/uploads/2023/10/chrzest-zoya.png',
    },
  },
};

  const envelopeData = {
    'a': {
      name: 'Biała',
      price: 0,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/30biala120.png',
    },
    'b': {
      name: 'Szara',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/40jasnoszara.png',
    },
    'c': {
      name: 'Czarna',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/40czarna.png',
    },
    'd': {
      name: 'Kremowa',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2022/09/kkremowa.png',
    },
    'e': {
      name: 'Beżowa',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/40bezowy.png',
    },
    'f': {
      name: 'Blady Róż',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/35bladyrozciemniejszy.png',
    },
    'g': {
      name: 'Brudny Róż',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/40brudnyrozjasniejszy1.png',
    },
    'h': {
      name: 'Bordowa',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/40ciemnebordo.png',
    },
    'i': {
      name: 'Granatowa',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/40granatowa.png',
    },
    'j': {
      name: 'Zielona',
      price: 0.75,
      image: 'https://kalmarry.pl/wp-content/uploads/2020/10/40ciemnozielona.png',
    },
  };

  const correctPin = '1111';

  const calculateTotalPrice = () => {
    if (modelData[selectedType] && modelData[selectedType][selectedModel] && envelopeData[selectedEnvelope]) {
      const modelPrice = modelData[selectedType][selectedModel].price;
      const envelopePrice = envelopeData[selectedEnvelope].price;
      const total = (modelPrice + envelopePrice) * quantity;
      setTotalPrice(total);
    } else {
      // Handle the case where the selected model or envelope does not exist
      console.error('Invalid selected model or envelope');
      // You can set a default price or handle the error as needed
    }
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedModel, selectedEnvelope, quantity]);

  const handleModelImageClick = (model) => {
    setSelectedModel(model);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const handleEnvelopeChange = (event) => {
    setSelectedEnvelope(event.target.value);
  };

  const handleEnvelopeImageClick = (envelope) => {
    setSelectedEnvelope(envelope);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handlePaymentClick = () => {

      if(quantity < 10)
      {
        setErrorQu("Nie możemy zrealizować zamówienia poniżej 10 sztuk!")
      }
      else
      {
        setErrorQu("")
        setIsModalOpen(true);
      }
        

    
  };


  const handleCheckboxChange = (e) => {
    setCheckbox1(e.target.checked);
  };

  const handleCheckbox2Change = (e) =>
  {
    setCheckbox2(e.target.checked)
  } 
  const handleSend = (e) => {

    setPinInputVisible(true)
  }
  const handleSend2 = (formValues, orderDetails) => {
    const requiredFields = {
      'inviterName': 'Proszę wypełnić wszystkie  pola',
      'eventDate': 'Proszę wypełnić wszystkie  pola',
      'eventTime': 'Proszę wypełnić wszystkie  pola',
      'eventLocation':'Proszę wypełnić wszystkie  pola',
      'receptionLocation': 'Proszę wypełnić wszystkie  pola',
      'orderContactNumber': 'Proszę wypełnić wszystkie  pola',
      'emailAddress': 'Proszę wypełnić wszystkie  pola',
    };
  
    const missingFields = Object.keys(requiredFields).filter(
      (field) => !formValues[field]
    );
  
    if (missingFields.length > 0) {
      const errorMessages = missingFields.map((field) => requiredFields[field]);
      errorMessages.forEach((message) => {
        console.log(message);
        setFormError(message); // This sets the formError state
      });
      return;
    }
  
    // Check if the email address is valid
    if (!isEmailValid(formValues.emailAddress)) {
      setFormError("Niepoprawny format adresu email");
      return;
    }
    if (!checkbox1 || !checkbox2)
    {
      setFormError("Potrzebujemy Twoich zgód w obu oświadczeniach!");
      return;
    }
    setPinInputVisible(true);
    setFormError("");
    // Continue with your code if all required fields are present
    if (pinInput === correctPin) {
      console.log("Correct pin!");
      setPinCorrect(true)
      setIsSending(true)
      fetch('sendEmail.php', {
        method: 'POST',
        body: JSON.stringify(Object.assign({}, formValues, orderDetails))
      })
      .then(response => response.json())
      .then(data => {
        console.log(data); // Handle the response from the PHP script
      })
      .catch(error => {
        console.error('Error:', error);
      });
      console.log(Object.assign({}, formValues, orderDetails));
    } else {
      console.log("Wrong Pin!");
    }
  };
  
  const handlePinInput = (pin) => {
    setPinInput(pin);
   
  };

  const isEmailValid = (email) => {
    // Regular expression to check if the email address is in a valid format
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    
    <StyledContainer style={styles.container}>
        <div>
    <Typography variant="h3" component="span" style={{ fontFamily: 'Lato', fontSize: '36px' }}>
     <img style={{width:"50%"}} src={Logo}></img>
    </Typography>


  </div>
 
      <form>
        <div style={styles.selectionContainer}>
      <FormControl>

     
    
   
<Typography variant="h5">Wybierz model zaproszenia:</Typography>
<RadioGroup
  row
  aria-label="invitation-type"
  name="invitation-type"
  value={selectedType}
  onChange={(event) => setSelectedType(event.target.value)}
  style={styles.cardsContainer}
>
  <FormControlLabel
    value="zaproszenia-slubne"
    control={<Radio style={{ color: '#EB578B' }} />} // Set the color for the radio button
    label="Zaproszenia ślubne"
  />
  <FormControlLabel
    value="zaproszenia-komunijne"
    control={<Radio style={{ color: '#EB578B' }} />} // Set the color for the radio button
    label="Zaproszenia komunijne"
  />
  <FormControlLabel
    value="zaproszenia-chrzest"
    control={<Radio style={{ color: '#EB578B' }} />} // Set the color for the radio button
    label="Zaproszenia na chrzest"
  />
</RadioGroup>
      <div style={styles.cardsContainer}>

        {Object.keys(modelData[selectedType]).map((model) => (
          
          <div key={model}>
            <ButtonBase
              onClick={() => handleModelImageClick(model)}
              style={{
                ...styles.modelCard,
                ...(selectedModel === model ? styles.selected : {}),
              }}
            >
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {modelData[selectedType][model].name}
                    </Typography>
                    <CardMedia
                      component="img"
                      alt={`Model ${model}`}
                      height="300"
                      image={modelData[selectedType][model].image}
                      style={{ display: 'block', margin: '0 auto' }}
                    />
                    <Typography variant="body2" color="textSecondary">
                    {modelData[selectedType][model].price + " zł"}
                    </Typography>
                  </CardContent>
                </Card>
                </ButtonBase>
          </div>
        ))}
      </div>
    </FormControl>
<br></br>
<div style={styles.selectionContainer}>
        <FormControl>
        <Typography variant="h5">Wybierz kopertę:</Typography>
      <div style={styles.envelopeContainer}>
        {Object.keys(envelopeData).map((envelope) => (
            <div key={envelope}>
              <ButtonBase
                onClick={() => handleEnvelopeImageClick(envelope)}
                style={{
                  ...styles.envelopeCard,
                  ...(selectedEnvelope === envelope ? styles.selected : {}),
                }}
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {envelopeData[envelope].name}
                    </Typography>
                    <CardMedia
                      component="img"
                      alt={`Model ${envelope}`}
                      height="100"
                      image={envelopeData[envelope].image}
                      style={{ display: 'block', margin: '0 auto' }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {envelopeData[envelope].price + " zł"}
                    </Typography>
                  </CardContent>
                </Card>
              </ButtonBase>
              </div>
        ))}
      </div>
    </FormControl>
    </div>
    </div>
        <br />
        <TextField
  type="number"
  label="Ilość zaproszeń"
  value={quantity}
  fullWidth
  onChange={handleQuantityChange}
  inputProps={{ min: 10 }} // Use inputProps directly for 'min'
/>

        <Typography variant="h5">Cena łączna: {totalPrice} zł</Typography>
        <p style={{color:'red'}}>{errorQu}</p>
        <Button
          variant="contained"
          color="primary"
          style={{width:"200px", marginTop:"20px", marginBottom:"20px", backgroundColor:"#F1598C"}}
          startIcon={!pinInputVisible ? <Payment /> : null}
          onClick={handlePaymentClick}
        >
          {!pinInputVisible ? 'Płatność' : 'Wyślij'}
        </Button>
    
      </form>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div style={{ ...styles.modal}}>
  <h2>Zamówienie zaproszeń ślubnych</h2>
  {pinCorrect ? ( <>
  
  
  <FancyPage></FancyPage>
  
  </> ) : ( <> 
    <div style={{ display: 'flex', gap:"20%" }}>
    
      <div>
       
        <p><strong>Wybrane opcje:</strong></p>
        <p><strong>Model zaproszenia:</strong> {modelData[selectedType][selectedModel]?.name}</p>
        <p><strong>Koperta:</strong> {envelopeData[selectedEnvelope]?.name}</p>
      </div>
      <div>
        <p><strong>Ilość zaproszeń:</strong> {quantity}</p>
        <p style={{fontSize:"25px"}}><strong>Cena łączna:</strong> {totalPrice} zł</p>
      </div>
    </div>

    {/* Imię i Nazwisko Osób / Osoby zapraszającej (Required) */}
    <TextField
      label="Imię i Nazwisko Osób / Osoby zapraszającej"
      required
      fullWidth
      style={{paddingBottom:"10px"}}
      InputLabelProps={{
        required: true,
        shrink: true,
        error: !inviterName, // Add your validation logic here
      }}
      InputProps={{
        endAdornment: !inviterName && (
          <InputLabel error></InputLabel>
        ),
      }}
      value={inviterName}
      onChange={(e) => setInviterName(e.target.value)}
    />
<br></br>
    {/* Data uroczystości DD-MM-RRRR (Required) */}
    <TextField
      label="Data uroczystości DD-MM-RRRR"
      required
      fullWidth
      style={{paddingBottom:"10px"}}
      InputLabelProps={{
        required: true,
        shrink: true,
        error: !eventDate, // Add your validation logic here
      }}
      InputProps={{
        endAdornment: !eventDate && (
          <InputLabel error></InputLabel>
        ),
      }}
      value={eventDate}
      onChange={(e) => setEventDate(e.target.value)}
    />
<br></br>
    {/* Godzina uroczystości (Required) */}
    <TextField
      label="Godzina uroczystości"
      required
      fullWidth
      style={{paddingBottom:"10px"}}
      InputLabelProps={{
        required: true,
        shrink: true,
        error: !eventTime, // Add your validation logic here
      }}
      InputProps={{
        endAdornment: !eventTime && (
          <InputLabel error></InputLabel>
        ),
      }}
      value={eventTime}
      onChange={(e) => setEventTime(e.target.value)}
    />
<br></br>
    {/* Miejsce uroczystości (Required) */}
    <TextField
      label="Miejsce uroczystości"
      required
      style={{paddingBottom:"10px"}}
      InputLabelProps={{
        required: true,
        shrink: true,
        error: !eventLocation, // Add your validation logic here
      }}
      InputProps={{
        endAdornment: !eventLocation && (
          <InputLabel error></InputLabel>
        ),
      }}
      fullWidth
      value={eventLocation}
      onChange={(e) => setEventLocation(e.target.value)}
    />
<br></br>
    {/* Miejsce przyjęcia (Required) */}
    <TextField
      label="Miejsce przyjęcia"
      required
      fullWidth
      style={{paddingBottom:"10px"}}
      InputLabelProps={{
        required: true,
        shrink: true,
        error: !receptionLocation, // Add your validation logic here
      }}
      InputProps={{
        endAdornment: !receptionLocation && (
          <InputLabel error></InputLabel>
        ),
      }}
      value={receptionLocation}
      onChange={(e) => setReceptionLocation(e.target.value)}
    />
<br></br>
    {/* Data potwierdzenia przybycia przez Gości (Not Required) */}
    <TextField
      label="Data potwierdzenia przybycia przez Gości"
      value={guestConfirmationDate}
      fullWidth
      style={{paddingBottom:"10px"}}
      onChange={(e) => setGuestConfirmationDate(e.target.value)}
    />
<br></br>
    {/* Numer lub numery telefonu (wraz z imionami) (Not Required) */}
    <TextField
      label="Numer lub numery telefonu (wraz z imionami)"
      value={phoneNumbers}
      fullWidth
      style={{paddingBottom:"10px"}}
      onChange={(e) => setPhoneNumbers(e.target.value)}
    />
<br></br>
    {/* Numer kontaktowy w sprawie zamówienia */}
    <TextField
      label="Numer kontaktowy w sprawie zamówienia"
      value={orderContactNumber}
      required
      style={{paddingBottom:"10px"}}
      InputLabelProps={{
        required: true,
        shrink: true,
        error: !orderContactNumber, // Add your validation logic here
      }}
      InputProps={{
        endAdornment: !orderContactNumber && (
          <InputLabel error></InputLabel>
        ),
      }}
      fullWidth
      onChange={(e) => setOrderContactNumber(e.target.value)}
    />
<br></br>
    {/* Adres email (Required) */}
    <TextField
      label="Adres email"
      required
      style={{paddingBottom:"10px"}}
      InputLabelProps={{
        required: true,
        shrink: true,
        error: !emailAddress, // Add your validation logic here
      }}
      InputProps={{
        endAdornment: !emailAddress && (
          <InputLabel error></InputLabel>
        ),
      }}
      fullWidth
      type="email"
      value={emailAddress}
      onChange={(e) => setEmailAddress(e.target.value)}
    />
                      <div>
                      <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox1}
                  onChange={handleCheckboxChange}
                  name="checkbox1"
                  required
                />
              }
              label="Zgadzam się na przetwarzanie moich danych osobowych wyłącznie w celu realizacji zamówienia, zgodnie z obowiązującym prawem o ochronie danych osobowych. Oświadczam również, że wszystkie podane dane są poprawne, nie zawierają błędów ani nieprawidłowości."
            /></div> <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox2}
                  onChange={handleCheckbox2Change}
                  name="checkbox2"
                  required
                />
              }
              label="Oświadczam, że jestem świadomy(a), iż zamówienie jest spersonalizowane, co oznacza, że nie przysługuje mi prawo do zwrotu produktów, zgodnie z obowiązującymi przepisami prawa konsumenckiego."
            />
                  </div>
                 
<br></br>
{pinInputVisible && (
  <div>
  
    <p style={{color:"red"}}>Proszę przekazać tablet kasjerowi w celu obsługi płatności zamówienia.</p>
    <TextField
      type="password"
      label="PIN"
      value={pinInput}
      onChange={(e) => handlePinInput(e.target.value)} // Handle changes in the PIN input
    />
             <Button onClick={() => handleSend2({
        inviterName,
        eventDate,
        eventTime,
        eventLocation,
        receptionLocation,
        guestConfirmationDate,
        phoneNumbers,
        orderContactNumber,
        emailAddress}, {        selectedModel,
          selectedEnvelope,
          selectedType,
          quantity,
          totalPrice})}>Ok</Button>
      
      </div>
    )}
 
 <StyledButton style={{marginRight:"40%"}} onClick={() => setIsModalOpen(false)} variant="contained" color="secondary" startIcon={<Cancel />}>Anuluj</StyledButton>
 <StyledButton
  onClick={() => handleSend2(
    {
      inviterName,
      eventDate,
      eventTime,
      eventLocation,
      receptionLocation,
      guestConfirmationDate,
      phoneNumbers,
      orderContactNumber,
      emailAddress
    },
    {
      selectedModel,
      selectedEnvelope,
      selectedType,
      quantity,
      totalPrice
    }
  )}
  variant="contained"
  color="primary"
  startIcon={<Payment />}
  style={{width:"200px", marginTop:"20px", marginBottom:"20px", backgroundColor:"#F1598C"}}>
  Wyślij
</StyledButton>
      {formError && <p style={{ color: 'red' }}>{formError}</p>}
      </> )}
  </div>
  
</Modal>
    </StyledContainer>
  );
};

export default OrderForm;